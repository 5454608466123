<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="6">
            <h1>Hackathon Submissions</h1>
          </b-col>
          <b-col cols="6" class="text-right">
            <el-tooltip content="refresh" placement="top">
              <span class="clickable"
                ><i
                  class="fas fa-redo"
                  :class="$apollo.loading ? 'fa-spin' : ''"
                  @click="
                    $apollo.queries.get_hackathon_judging_submissions.skip = false;
                    $apollo.queries.get_hackathon_judging_submissions.refetch();
                  "
                ></i
              ></span>
            </el-tooltip>
          </b-col>
        </b-row>

        <div v-if="$apollo.loading && table_data.length == 0">
          <b-row class="mt-2 d-flex align-items-center justify-content-center">
            <b-col class="d-flex align-items-center justify-content-center">
              <Transition>
                <h1>
                  <i class="fas fa-spinner fa-spin ml-1"></i>
                </h1>
              </Transition>
            </b-col>
          </b-row>
        </div>
        <b-row v-else>
          <b-col>
            <el-table
              class="table-responsive align-items-center table-flush no-lines"
              header-row-class-name="thead-light"
              row-class-name="table-rows"
              :data="table_data"
              :empty-text="`${
                hackathon.is_creator
                  ? 'No teams have submitted their work.'
                  : 'Teams are busy working and will submit their work soon...'
              }`"
            >
              <el-table-column label="Name" min-width="150px" sortable>
                <template v-slot="{ row }">
                  <p class="mb-0">
                    <router-link
                      :to="{
                        name: 'HackathonJudgeSubmission',
                        params: {
                          hackathon_id: hackathon_id,
                          submission_id: row.id,
                        },
                      }"
                    >
                      {{ row.team.name }}
                    </router-link>
                  </p>
                </template>
              </el-table-column>

              <!-- <el-table-column
                                label="Description"
                                min-width="350px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p
                                        class="mb-0"
                                        v-html="row.description"
                                    ></p>
                                </template>
                            </el-table-column> -->

              <!-- <el-table-column
                                label="Type"
                                min-width="240px"
                                sortable
                                v-if="
                                    is_hackathon_manager || is_hackathon_creator
                                "
                            >
                                <template v-slot="{ row }">
                                    <p
                                        class="mb-0"
                                        v-html="row.type_display"
                                    ></p>
                                </template>
                            </el-table-column> -->
              <el-table-column
                label="My Score"
                min-width="90"
                v-if="hackathon.is_judge"
              >
                <template v-slot="{ row }">
                  {{ row.my_score }}/{{ row.max_score }}
                </template>
              </el-table-column>
              <el-table-column
                min-width="90px"
                label="Scored"
                v-if="hackathon.is_judge"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip
                    :content="
                      row.judging_status
                        ? `Judging is finalised`
                        : `Please click on the 'Complete' button inside the team juding page to finalise your jugding.`
                    "
                    placement="top"
                  >
                    <b-badge
                      pill
                      :variant="row.judging_status ? 'info' : 'primary'"
                      >{{ row.judging_status ? "Done" : "To Judge" }}</b-badge
                    >
                  </el-tooltip>
                </div>
              </el-table-column>

              <el-table-column
                label="Team Score"
                min-width="100"
                v-if="hackathon.is_creator || hackathon.is_manager"
              >
                <template v-slot="{ row }">
                  {{ row.average_score }}/{{ row.max_score }}
                </template>
              </el-table-column>

              <el-table-column label="Judging Progress" min-width="130">
                <template v-slot="{ row }">
                  {{ row.judging_progress }}/{{ row.total_judges }}
                  completed
                </template>
              </el-table-column>

              <el-table-column
                min-width="90px"
                label="Status"
                v-if="hackathon.is_creator || hackathon.is_manager"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip
                    :content="
                      row.judging_status
                        ? `Judging is finalised`
                        : `Please click on the 'Complete' button inside the team juding page to finalise your jugding.`
                    "
                    placement="top"
                  >
                    <b-badge
                      pill
                      :variant="row.judging_status ? 'info' : 'primary'"
                      >{{
                        row.judging_status ? "Judging Complete" : "In Progress"
                      }}</b-badge
                    >
                  </el-tooltip>
                </div>
              </el-table-column>

              <!-- <el-table-column
                                min-width="180px"
                                align="center"
                                v-if="hackathon.is_creator"
                            >
                                <div slot-scope="{ row }" class="table-actions">
                                    <el-tooltip content="Edit" placement="top">
                                        <a
                                            href="#!"
                                            @click.prevent="cru_modal_show(row)"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Update Judging Criteria"
                                        >
                                            <i class="fas fa-edit"></i>
                                        </a>
                                    </el-tooltip>

                                    <el-tooltip
                                        content="Delete"
                                        placement="top"
                                    >
                                        <a
                                            href="#!"
                                            @click.prevent="d_modal_show(row)"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Update Judging Criteria"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </el-tooltip>
                                </div>
                            </el-table-column> -->
            </el-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// Modules
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
} from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import Badge from "@/components/Badge";

import { processTeamRelayData } from "@/ORM/Hackathon/Team.js";
import {
  processHackathonRelayData,
  Hackathon,
} from "@/ORM/Hackathon/Hackathon.js";

// Queries
import { GET_HACKATHON_JUDGING_SUBMISSIONS } from "@/graphql/queries";

// Mutations

export default {
  name: "HackthonCrudSubmissionsTable",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    hackathon_id: {
      type: String,
      description: "The Id of the hackathon being viewed.",
      default: null,
    },
  },
  apollo: {
    get_hackathon_judging_submissions: {
      query: GET_HACKATHON_JUDGING_SUBMISSIONS,
      result(res) {
        this.table_data = processTeamRelayData(res);

        this.hackathon = processHackathonRelayData(res) || new Hackathon();
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          hackathon_id: this.hackathon_id,
        };
      },
      update(data) {
        this.apollo_data.get_hackathon_judging_submissions = data;
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon_judging_submissions: null,
      },
      table_data: [], // All the criteria table data is in here.
      hackathon: new Hackathon(),
    };
  },
  methods: {
    manage_get_hackathon_judging_submissions() {
      if (this.hackathon_id) {
        graph_utils.enable_query(
          this.$apollo.queries.get_hackathon_judging_submissions
        );
      } else {
        graph_utils.disable_query(
          this.$apollo.queries.get_hackathon_judging_submissions
        );
      }
    },
  },
  watch: {
    hackathon_id() {
      this.manage_get_hackathon_judging_submissions();
    },
  },
  mounted() {
    if (!this.$apollo.queries.get_hackathon_judging_submissions.skip) {
      this.$apollo.queries.get_hackathon_judging_submissions.refetch();
    }
  },
  created() {
    // setting this queries fetch policy
    graph_utils.set_fetch_cache_and_network(
      this.$apollo.queries.get_hackathon_judging_submissions
    );
  },
};
</script>

<style></style>
