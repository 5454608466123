<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12"> </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container fluid class="mt--6">
            <!-- <b-row>
                <b-col class="mt-4 mb-2">
                    <div
                        @click="$router.back()"
                        class="icon icon-shape rounded-circle shadow bg-primary text-white"
                    >
                        <i class="fa fa-arrow-left"></i>
                    </div>
                </b-col>
            </b-row> -->
            <b-row>
                <b-col sm="12">
                    <div>
                        <CrudSubmissionsList
                            :hackathon_id="hackathon_id"
                        ></CrudSubmissionsList>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import CrudSubmissionsList from "@/views/Components/Hackathon/Judging/CrudSubmissionList.vue";

export default {
    name: "HackathonCrudJudgingCriteria",
    components: {
        CrudSubmissionsList,
    },
    data() {
        return {
            hackathon_id: null,
        };
    },
    methods: {
        get_hackathon_id() {
            if (this.$route.params.hackathon_id) {
                this.hackathon_id = this.$route.params.hackathon_id;
            } else {
                this.hackathon_id = null;
                // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
            }
        },
    },
    mounted() {
        this.get_hackathon_id();
    },
};
</script>

<style></style>
